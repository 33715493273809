<template>
    <div>
        <BasicDetailHeaderComponent :promises.sync="promises"
            :title="organizationName" retrieveAvatar="organizationModule/getOrganizationLogo"
            :titleEditRoute="{ name: routeNames.ORGANIZATION_DETAIL_EDIT_ORGANIZATION_NAME, params: { organizationId: organizationId } }"
            :avatarEditRoute="{ name: routeNames.ORGANIZATION_DETAIL_EDIT_LOGO, params: { organizationId: organizationId } }" default-avatar="default-organization-avatar.svg"
            disableActionButtons class="notranslate">
            <template v-slot:content>
                <v-col cols="12">
                    Organisatie id: {{ organizationId }}
                    <CopyText :textToCopy="organizationId" displayCopyIcon />
                </v-col>
            </template>
        </BasicDetailHeaderComponent>
    </div>
</template>

<script>
import BasicDetailHeaderComponent from "../shared/BasicDetailHeaderComponent.vue"
import routeNames from "@/router/RouteNames"
import CopyText from "@/components/shared/actions/CopyText"

export default {
    name: "OrganizationDetailHeaderComponent",
    components: {
        CopyText,
        BasicDetailHeaderComponent,
    },
    props: {
        organizationId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            promises: [this.getOrganizationNameDto(),this.$store.dispatch('organizationModule/fetchOrganizationLogo')],
            routeNames,
            organizationName: null,
            SASKey: null,
        }
    },
    methods: {
        getOrganizationNameDto() {
            return this.$store.dispatch("organizationModule/fetchOrganizationName", this.organizationId)
                .then(result => {
                    this.organizationName = result.name
                })
        },

    },
}
</script>
